<template>
  <!-- Footer -->
  <MDBFooter :text="['center', 'lg-start', 'muted']">
    <!-- Section: Social media -->
    <section class="
        d-flex
        justify-content-center justify-content-lg-between
        p-4
        border-bottom
      ">
      <!-- Left -->
      <div class="me-5 d-none d-lg-block">
        <span>Get connected with us on social networks:</span>
      </div>
      <!-- Left -->
      <!-- Right -->
      <div>
        <a href="https://twitter.com/emillium" target="_blank" class="me-4 text-reset">
          <MDBIcon iconStyle="fab" icon="twitter"></MDBIcon>
        </a>
        <a href="https://www.linkedin.com/company/emillium" target="_blank" class="me-4 text-reset">
          <MDBIcon iconStyle="fab" icon="linkedin"></MDBIcon>
        </a>
        <a href="https://github.com/emillium" target="_blank" class="me-4 text-reset">
          <MDBIcon iconStyle="fab" icon="github"></MDBIcon>
        </a>
      </div>
      <!-- Right -->
    </section>
    <!-- Section: Social media -->
    <!-- Section: Links  -->
    <section class="">
      <MDBContainer class="text-center text-md-start mt-5">
        <MDBRow class="mt-3">
          <MDBCol md="3" lg="4" xl="4" class="mx-auto mb-4">
            <!-- Content -->
            <h6 class="text-uppercase fw-bold mb-4">
              <i class="fas fa-code me-3"></i>EMILLIUM
            </h6>
            <p>
              Emillium is an Australian based IT Consultancy specialising in the management and development
              of applications for mobile and web domains. We specialise in the integration of API's and the
              extension of business SaaS platforms to customise the needs of small to medium sized business.
            </p>
          </MDBCol>
          <!--MDBCol md="3" lg="2" xl="2" class="mx-auto mb-4">
            <h6 class="text-uppercase fw-bold mb-4">Useful links</h6>
            <p>
              <router-link to="/#about" class="text-reset">About</router-link>
            </p>
            <p>
              <router-link to="/#portfolio" class="text-reset">Portfolio</router-link>
            </p>
            <p>
              <router-link to="/#contact" class="text-reset">Contact</router-link>
            </p>
            <p>
              <router-link class="text-reset" to="/privacy">Privacy Policy</router-link>
            </p>
          </MDBCol-->
          <MDBCol md="4" lg="3" xl="3" class="mx-auto mb-md-0 mb-4">
            <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
            <p>
              <i class="fas fa-home me-3"></i> Queensland, Australia
            </p>
            <p>
              <i class="fas fa-envelope me-3"></i>
              info@emillium.com
            </p>
            <p>
              <i class="fas fa-phone me-3"></i> +61 411 086 145
            </p>
            <p>
              <i class="fas fa-user-secret me-3"></i> <router-link class="text-reset" to="/privacy">Privacy Policy</router-link>
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05)">
      © 2022 Copyright:
      <a class="text-reset fw-bold" href="http://emillium.com/">Emillium.com</a>
    </div>
    <!-- Copyright -->
  </MDBFooter>
  <!-- Footer -->
</template>

<script>
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-vue-ui-kit';
export default {
  components: {
    MDBFooter,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBIcon
  },
};
</script>

<style scoped>

</style>