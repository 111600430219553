<template>
  <div id="portfolio" class="form-body">
    <h2>OUR CLIENTS</h2>
    <p>We have successfully delivered high traffic commercial business applications targeted to local, national, and international audiences. Some of our clients have included:</p>
    <MDBRow>
      <MDBCol sm="2">
        <div class="card">
          <img src="img/elynx.jpg" class="card-img-top" alt="Elynx" width="75%"/>
          <div class="card-body">
            <h5 class="card-title">Elynx<br/></h5>
          </div>
        </div>
      </MDBCol>
      <MDBCol sm="2">
        <div class="card">
          <img src="img/ellerfield.jpg" class="card-img-top" alt="Ellerfield Financial Planning" width="75%"/>
          <div class="card-body">
            <h5 class="card-title">Ellerfield Financial Planning</h5>
          </div>
        </div>
      </MDBCol>
      <MDBCol sm="2">
        <div class="card">
          <img src="img/jameshardie.jpg" class="card-img-top" alt="James Hardie Concrete Pipes" width="75%"/>
          <div class="card-body">
            <h5 class="card-title">James Hardie Concrete Pipes</h5>
          </div>
        </div>
      </MDBCol>
      <MDBCol sm="2">
        <div class="card">
          <img src="img/redplanet.jpg" class="card-img-top" alt="Red Planet Software" width="75%"/>
          <div class="card-body">
            <h5 class="card-title">Red Planet Software</h5>
          </div>
        </div>
      </MDBCol>
      <MDBCol sm="2">
        <div class="card">
          <img src="img/awx.jpg" class="card-img-top" alt="AWX" width="75%"/>
          <div class="card-body">
            <h5 class="card-title">AWX<br/></h5>
          </div>
        </div>
      </MDBCol>
      <MDBCol sm="2">
        <div class="card">
          <img src="img/4impact.jpg" class="card-img-top" alt="4Impact" width="75%"/>
          <div class="card-body">
            <h5 class="card-title">4Impact<br/></h5>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
export default {
  name: "OurHistory",
  components: {
    MDBRow,
    MDBCol
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#portfolio {
  padding-top: 35px;
}
#portfolio h2, #portfolio p {
  text-align: left;
}
div.spacer {
  height: 25px;
}
h5 {
  font-size: 12pt;
  font-weight: bold;
  text-align: center;
  height: 70px;
}
p.card-text {
  padding-top: 10px;
  font-size: 9pt;
}
div.card {
  padding: 10px;
  text-align: center;
}
div.card img {
  padding: 20px;
}
</style>
