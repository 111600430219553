<template>
  <AboutUs/>
  <OurHistory/>
  <ContactUs/>
</template>

<script>
import AboutUs from '@/components/AboutUs.vue'
import OurHistory from '@/components/OurHistory.vue'
import ContactUs from '@/components/ContactUs.vue'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components: {
    AboutUs,
    OurHistory,
    ContactUs
  }
}
</script>

<style>
h2 {
  padding-top: 30px;
}
</style>
