import {createRouter, createWebHistory} from "vue-router";
import home from '@/components/Home.vue'
import privacy from '@/components/Privacy.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
