<template>
  <div id="contact">
    <h2 id="contact">CONTACT US</h2>
    <p>Please fill out the following contact form to engage with Emillium for your business or project. Emillium has a
      commitment to protect your privacy and information, so please <u>do not</u> submit any sensitive information like
      passwords, credit cards or bank account details.</p>
    <div class="form-body">
      <form ref="form" @submit.prevent="submit" @reset="onReset">
        <div class="form-group">
          <label>Name</label>
          <input type="text" class="form-control" v-model="name" name="user_name">
        </div>
        <div class="form-group">
          <label>Email</label>
          <input type="email" class="form-control" v-model="email" name="user_email">
        </div>
        <div class="form-group">
          <label>Message</label>
          <textarea v-model="message" class="form-control" name="message"></textarea>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary">Send</button>
          <button type="reset" class="btn">Reset</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
  computed: {
    formValid() {
      const {name, email, message} = this;
      return (
          name.length > 0 &&
          /(.+)@(.+){2,}.(.+){2,}/.test(email) &&
          message.length > 0
      );
    },
  },
  methods: {
    onReset() {
      this.name = "";
      this.email = "";
      this.message = "";
    },
    submit() {
      if (!this.formValid) {
        console.log('INVALID!');
        return;
      }
      emailjs.sendForm('service_g34zkbe', 'template_cfles0q', this.$refs.form, '0NNyyFrUYy-ZXXeYs')
          .then((result) => {
            console.log('SUCCESS!', result.text);
          }, (error) => {
            console.log('FAILED...', error.text);
          });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#contact {
  padding-top: 35px;
}

#contact h2 {
  text-align: left;
}

label {
  width: 100px;
  vertical-align: top;
}

button {
  margin-right: 15px;
}

button.btn-primary {
  --bs-btn-bg: #981b1e;
  --bs-btn-border-color: #981b1e;
  --bs-btn-hover-border-color: #981b1e;
  --bs-btn-active-bg: #981b1e;
  --bs-btn-hover-bg: #570f11;
  --bs-btn-active-border-color: #981b1e;
  --bs-btn-disabled-bg: #981b1e;
  --bs-btn-disabled-border-color: #981b1e;
}

button.btn-primary:hover {
  --bs-btn-bg: #570f11;
}

div.form-group {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
}
</style>
