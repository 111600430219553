<template>
  <div id="about">
    <h2>ABOUT EMILLIUM</h2>
    <p>Emillium has the experience and expertise in bridging the business-tech divide to deliver dynamic information
      technology solutions for small to medium Australian businesses and organisations. We are experienced in solving
      business problems across a vast and varied portfolio of industries and business domains.</p>
    <p>We can work with your business to define and simplify your business information technology needs and processes to
      help your business achieve its goals and objectives and drive its vision. We then develop, integrate and implement
      web and mobile enable systems to service the requirements of your business, clients and customers. Our systems are
      agile, intuitive, and client focussed.</p>
    <p>Our consultants bring more than twenty years of industry experience with them, having previously worked for
      national and international organisations and companies including <strong><i>Google Cloud</i></strong>,
      <strong><i>YouTube</i></strong>, <strong><i>Amazon</i></strong>, <strong><i>Queensland Police Service</i></strong>,
      <strong><i>Australian Federal Government</i></strong>, <strong><i>IBM Global Services Australia</i></strong>
      and <strong><i>Telstra</i></strong>.
    </p>
  </div>
</template>

<script>
export default {
  name: "WhatDo"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#about {
  padding-top: 35px;
}

#about h2, #about p {
  text-align: left;
}
</style>
