<template>
  <!-- Navbar -->
  <MDBNavbar expand="lg" dark bg="dark" container position="sticky">
    <MDBNavbarBrand href="#">
      <img src="../assets/logo.png" height="90" alt="" loading="lazy" />
    </MDBNavbarBrand>
    <MDBNavbarToggler @click="collapse1 = !collapse1" target="#navbarSupportedContent"></MDBNavbarToggler>
    <MDBCollapse v-model="collapse1" id="navbarSupportedContent">
      <MDBNavbarNav class="mb-2 mb-lg-0">
        <MDBNavbarItem to="#" class="header-link" active>
          <router-link to="/" class="header-link">Home</router-link>
        </MDBNavbarItem>
        <MDBNavbarItem to="#">
          <router-link to="/privacy" class="header-link">Privacy</router-link>
        </MDBNavbarItem>
      </MDBNavbarNav>
    </MDBCollapse>
  </MDBNavbar>
  <div id="banner">
  </div>
  <div id="banner-border">
  </div>
</template>

<script>
import { MDBCollapse, MDBNavbar, MDBNavbarToggler, MDBNavbarNav, MDBNavbarItem } from 'mdb-vue-ui-kit';
import { ref } from 'vue';
export default {
  components: {
    MDBCollapse,
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem
  },
  setup() {
    const collapse1 = ref(false);
    return {
      collapse1
    }
  }
};
</script>

<style scoped>
div#banner {
  background-image: linear-gradient(to right, #981b1e, #ed1e24);
  height: 20px;
}
div#banner-border {
  background-image: linear-gradient(to right, #9e8a31, #d8cd95);
  height: 10px;
}
a.header-link {
  text-decoration: none;
  color: white;
}
</style>